import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Button,
  FormHelperText,
  Stack,
  CardContent,
  Autocomplete,
  TextField,
  Grid,
  Box,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { api, urls } from "../../services";
import { useToast } from "../../contexts";
import { useAuth } from "../../contexts";
import { logo } from "../../assets";
import { AuthCard } from "../../components";
import { countriesList } from "../../assets";

//---------------components -------->
import { EmailTextField } from "../../components";

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    paddingTop: "20px",
  },
  verticalCenter: {
    // alignItems: "center",
    display: "flex !important",
  },
  logoImageXs: {
    width: 150,
    cursor: "pointer",
    marginBottom: 30,
  },
  mainGrid: {
    padding: "20px !important",
    [theme.breakpoints.down("md")]: {
      padding: "10px !important",
    },
  },
  cardContent: {
    "&.MuiCardContent-root": {
      // padding: "20px !important",
      width: "100%",
      // [theme.breakpoints.down("md")]: {
      //   padding: "10px !important",
      // },
    },
  },
  titleText: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: "28px",
      color: "#1A1A1A",
    },
  },
  titleTextSub: {
    "&.MuiTypography-root": {
      fontWeight: 600,
      fontSize: 16,
      color: "#000",
    },
  },
  linkText: {
    color: theme.palette.common.primary,
  },
}));
//---------------customised button --------->
const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: theme.palette.common.primary,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 700,
  // padding: "10px 16px !important",
  height: "50px",
  "&:hover": {
    backgroundColor: theme.palette.common.primaryHover,
  },
}));

//---------------customised checkbox --------->
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  padding: "0 !important",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#0F8EFF",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#0F8EFF",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

export function SignInDetails() {
  const classes = useStyles();
  const { updateUserData } = useAuth();
  const theme = useTheme();

  const [formData, setFormData] = useState({
    first_name: "",
    email: "",
    country: "",
    state: "",
    dob: "",
    religion: "",
    occupation: "",
    accept_terms: false,
  });
  const [buttonTapped, setButtonTapped] = useState(false);
  const [apiCalling, setApiCalling] = useState(false);
  const { showToast } = useToast();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      return curr;
    });
  };

  const updateDOB = (date) => {
    if (date) {
      console.log(date.$y);
      let currentDate = new Date();
      let formattedDate = dayjs(date.$d).format("DD-MMM-YYYY");

      if (date.$y <= currentDate.getFullYear()) {
        setFormData((prev) => {
          const curr = { ...prev };
          curr.dob = formattedDate;
          return curr;
        });
      }
    } else {
      setFormData((prev) => {
        const curr = { ...prev };
        curr.dob = "";
        return curr;
      });
    }
  };

  const handleContinueClick = () => {
    setButtonTapped(true);

    const isValidForm = () => {
      const requiredFields = [
        formData.first_name,
        formData.country,
        formData.state,
        formData.dob,
        formData.religion,
        formData.occupation,
        formData.email,
      ];

      if (requiredFields.some((field) => !field.trim())) {
        return false;
      }

      if (formData.accept_terms === false || !validateEmail(formData.email)) {
        return false;
      }

      return true;
    };

    if (isValidForm()) {
      setApiCalling(true);
      api
        .post(urls.RegisterUser, formData)
        .then((res) => {
          console.log("🚀 ~ .then ~ res.data:", res.data);
          const { data } = res.data;
          showToast(res.data.message, "success");
          updateUserData(data.user_data);
          setApiCalling(false);
        })
        .catch((err) => {
          setApiCalling(false);
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  return (
    <AuthCard>
      <Grid container spacing={0} className={classes.mainGrid}>
        <Grid item md={12} sx={{ display: { xs: "none", md: "block" } }}>
          <Box
            className={classes.logoContainer}
            sx={{
              mr: 1,
              textAlign: "center",
            }}
          >
            <img className={classes.logoImageXs} alt="logo" src={logo} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }}>
              <Typography
                className={classes.titleTextSub}
                gutterBottom
                variant="h5"
                component="div"
              >
                Name
              </Typography>

              <EmailTextField
                value={null}
                error={formData.first_name.trim() === "" && buttonTapped}
                helperText={
                  formData.first_name.trim() === "" && buttonTapped
                    ? "Please enter your full name"
                    : null
                }
                onChange={(e) => updateFormData("first_name", e.target.value)}
              />
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }}>
              <Typography
                className={classes.titleTextSub}
                gutterBottom
                variant="h5"
                component="div"
              >
                Email
              </Typography>

              <EmailTextField
                value={null}
                error={formData.email.trim() === "" && buttonTapped}
                helperText={
                  formData.email.trim() === "" && buttonTapped
                    ? "Please enter your email"
                    : null
                }
                onChange={(e) => updateFormData("email", e.target.value)}
              />
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }}>
              <Typography
                className={classes.titleTextSub}
                gutterBottom
                variant="h5"
                component="div"
              >
                Country
              </Typography>

              {/* <Autocomplete
                value={null}
                error={formData.country.trim() === "" && buttonTapped}
                helperText={
                  formData.country.trim() === "" && buttonTapped
                    ? "Select your country"
                    : null
                }
                onChange={(e) => updateFormData("country", e.target.value)}
              /> */}
              <Autocomplete
                id="country-select-demo"
                options={countriesList}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option.label}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Choose a country"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                    error={formData.country.trim() === "" && buttonTapped}
                    helperText={
                      formData.country.trim() === "" && buttonTapped
                        ? "Please select your country"
                        : null
                    }
                  />
                )}
                onSelect={(e) => updateFormData("country", e.target.value)}
              />
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }}>
              <Typography
                className={classes.titleTextSub}
                gutterBottom
                variant="h5"
                component="div"
              >
                State
              </Typography>

              <EmailTextField
                value={null}
                error={formData.state.trim() === "" && buttonTapped}
                helperText={
                  formData.state.trim() === "" && buttonTapped
                    ? "Please enter your state"
                    : null
                }
                onChange={(e) => updateFormData("state", e.target.value)}
              />
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }}>
              <Typography
                className={classes.titleTextSub}
                gutterBottom
                variant="h5"
                component="div"
              >
                DOB
              </Typography>

              {/* <EmailTextField
                value={null}
                error={formData.dob.trim() === "" && buttonTapped}
                helperText={
                  formData.dob.trim() === "" && buttonTapped
                    ? "Please enter your date of birth"
                    : null
                }
                onChange={(e) => updateFormData("dob", e.target.value)}
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]} sx={{ pt: "-10px" }}>
                  <DatePicker
                    // label=""
                    sx={{ width: "100%" }}
                    disableFuture
                    format="DD-MMM-YYYY"
                    onChange={(date) => updateDOB(date)}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {formData.dob.trim() === "" && buttonTapped && (
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: 13,
                    color: "#d32f2f",
                    pl: 2,
                    fontWeight: 400,
                  }}
                >
                  Please enter your date of birth
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }}>
              <Typography
                className={classes.titleTextSub}
                gutterBottom
                variant="h5"
                component="div"
              >
                Religion
              </Typography>

              <EmailTextField
                value={null}
                error={formData.religion.trim() === "" && buttonTapped}
                helperText={
                  formData.religion.trim() === "" && buttonTapped
                    ? "Please enter your religion"
                    : null
                }
                onChange={(e) => updateFormData("religion", e.target.value)}
              />
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={{ xs: 1, sm: 1, md: 1 }}>
              <Typography
                className={classes.titleTextSub}
                gutterBottom
                variant="h5"
                component="div"
              >
                Occupation
              </Typography>

              <EmailTextField
                value={null}
                error={formData.occupation.trim() === "" && buttonTapped}
                helperText={
                  formData.occupation.trim() === "" && buttonTapped
                    ? "Please enter your occupation"
                    : null
                }
                onChange={(e) => updateFormData("occupation", e.target.value)}
              />
            </Stack>
          </CardContent>
        </Grid>
        <Grid item xs={12} md={6} className={classes.verticalCenter}>
          <CardContent className={classes.cardContent}>
            <Stack direction="column" spacing={0}>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ fontWeight: 400, fontSize: 16 }}
              >
                <BpCheckbox
                  checked={formData.accept_terms}
                  onChange={(e) =>
                    updateFormData("accept_terms", !formData.accept_terms)
                  }
                />
                I accept <span className={classes.linkText}>terms of use</span>{" "}
                and <span className={classes.linkText}>privacy policy.</span>
                {formData.accept_terms === false && buttonTapped && (
                  <FormHelperText sx={{ color: "#d32f2f" }}>
                    Please agree the Terms of use and Privacy policy
                  </FormHelperText>
                )}
              </Typography>
              <ColorButton
                variant="contained"
                disableElevation
                fullWidth
                disableRipple
                onClick={handleContinueClick}
                disabled={apiCalling}
              >
                Continue
              </ColorButton>
            </Stack>
          </CardContent>
        </Grid>
      </Grid>
    </AuthCard>
  );
}
// ==============================|| Login PAGE ||============================== //
