import React, { useState, useEffect, Fragment } from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Link,
  Stack,
  Typography,
  Button,
  useTheme,
  alpha,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  BreadCrumbList,
  TitleBar,
  TopArticleCard,
  ArticleCard,
} from "../../components";
import { Ellipse } from "../../assets";
import { styled } from "@mui/material/styles";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";
import { useParams } from "react-router-dom";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "75vh",
  },
  cardTop: {
    borderRadius: 6,
  },
  cardLayout: {
    "&.MuiCard-root": {
      borderRadius: "3px",
      boxShadow: "none",
      background: "transparent",
    },
  },
  cardImage: {
    boxShadow: "none",
    width: "100%",
    aspectRatio: "1/1",
    borderRadius: "50%",
    [theme.breakpoints.down("md")]: {
      height: "170px",
      width: "80%",
    },
  },
  cardTitle: {
    "&.MuiTypography-root": {
      fontSize: "22px",
      marginTop: "20px",
      textAlign: "center",
      fontWeight: "700",
      lineHeight: "20px",
      marginBottom: "10px",
      color: " #000",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
    },
  },
  cardSubTitle: {
    "&.MuiTypography-root": {
      fontSize: "18px",
      textAlign: "center",
      fontWeight: "500",
      lineHeight: "20px",
      color: "black",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  imageContainer: {
    // margin: '-88px 0 0 auto',
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  EllipseImage: {
    width: "-webkit-fill-available",
    marginTop: "10px",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
}));

//<!-----------------------sample Data------------------>
const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.common.primary,
  textTransform: "none",
  fontSize: 20,
  width: "100%",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: theme.palette.common.primaryHover,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

export function AuthorDetail() {
  const [detailsData, setDetailsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { authorID } = useParams();
  const { showToast } = useToast();
  const classes = useStyles();
  const { logout, userData } = useAuth();
  const theme = useTheme();

  const getAuthorDetails = () => {
    setIsLoading(true);
    api
      .get(urls.authorDetails + authorID)
      .then((res) => {
        setIsLoading(false);
        setDetailsData(res.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  function handleBuyClick() {
    window.open(`/buy-magazine/${userData.id}/${detailsData.id}`, "_blank");
  }

  useEffect(() => {
    getAuthorDetails();
  }, []);

  return (
    <Fragment>
      <Container
        maxWidth="xl"
        className={classes.root}
        sx={{ paddingRight: "0px !important" }}
      >
        <BreadCrumbList pathnames={[{ text: "Author details", link: "" }]} />
        {isLoading && (
          <Box
            height={600}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <LoaderCard />
          </Box>
        )}
        {!isLoading && (
          <Grid
            container
            className={classes.container}
            spacing={4}
            direction="row"
            justifyContent={"center"}
          >
            <Grid
              item
              md={3}
              sm={4}
              xs={12}
              sx={{
                justifyContent: "center",
                display: {
                  sm: "block",
                  xs: "flex",
                },
              }}
            >
              <Card
                className={classes.cardLayout}
                // sx={{ maxWidth: { xs: 260, md: 600 } }}
              >
                <CardContent
                  className={classes.cardTop}
                  sx={{
                    padding: {
                      xs: "20px 30px",
                      md: "20px 50px",
                    },
                    position: "relative",
                    backgroundColor: "#F3F3F3",
                  }}
                >
                  <Box className={classes.imageContainer}>
                    <Box
                      component={"img"}
                      className={classes.cardImage}
                      alt="Author Image"
                      src={detailsData?.author?.author_image}
                    />
                    <Box
                      component={"img"}
                      className={classes.EllipseImage}
                      alt="Magazine Image"
                      src={Ellipse}
                    />
                  </Box>
                  <Stack spacing={2} direction={"column"}>
                    <Typography className={classes.cardTitle}>
                      {detailsData?.author?.author_name}
                    </Typography>
                    <Typography className={classes.cardSubTitle}>
                      {detailsData?.author?.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={9} sm={8} xs={12}>
              <Grid
                container
                spacing={2}
                direction="row"
                sx={{
                  justifyContent: {
                    md: "left",
                    sm: "center",
                    xs: "center",
                  },
                  display: {
                    md: "inline-flex",
                    sm: "flex",
                    xs: "flex",
                  },
                }}
              >
                <Grid item xs={12}>
                  <TitleBar Title="Articles" />
                </Grid>
                {detailsData?.author?.articles?.map((post, i) => {
                  return (
                    <Grid
                      item
                      sm={6}
                      xr={6}
                      md={3}
                      key={i}
                      // sx={{
                      //   justifyContent: "center",
                      //   display: {
                      //     sm: "flex",
                      //     md: "block",
                      //   },
                      // }}
                    >
                      <Link
                        href={`/article-details/${post.id}`}
                        rel="noopener noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <TopArticleCard
                          iconPrimary={post.feature_image}
                          primary={post.title}
                          //   secondary={detailsData?.author?.author_name}
                          //   authorImage={detailsData?.author?.author_image}
                          secondarySub={
                            post.article_categories[0].descriptions[0]
                              .category_name
                          }
                          color={post.article_categories[0].category_colour}
                          backgroundColor={alpha(
                            post.article_categories[0].category_colour,
                            0.1
                          )}
                          // onCardClick={() => {
                          //   navigate(`/article-details/${post.id}`);
                          // }}
                        />
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </Fragment>
  );
}
