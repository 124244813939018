import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  Button,
  Box,
} from "@mui/material";
import { banner, bannerDevice, android, ios } from "../../assets";

// style constant
const useStyles = makeStyles((theme) => ({
  mainCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "35px",
      boxShadow: "none !important",
      backgroundSize: "cover",
      overflow: "visible",
      height: "378px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        borderRadius: "40px",
      },
    },
  },
  textPrimary: {
    "&.MuiTypography-root": {
      fontSize: "32px",
      color: "white",
      paddingBottom: "15px",
      fontWeight: 700,
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        textAlign: "center",
        marginBottom: "10px",
      },
    },
  },
  layoutPadding: {
    "&.MuiBox-root": {
      padding: "0px 0px 0px 70px",
      [theme.breakpoints.down("md")]: {
        padding: "10px",
      },
    },
  },
  btnBanner: {
    "&.MuiButton-root": {
      borderRadius: "6px",
      backgroundColor: "black",
      textTransform: "capitalize",
      margin: "8px",
      width: "230px",
      heigh: "65px",
      "&:hover": {
        backgroundColor: "black",
      },
      [theme.breakpoints.down("md")]: {
        width: "140px",
        heigh: "49px",
      },
    },
  },
  bannerOuterLayer: {
    padding: "100px 0px",
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "50px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px",
    },
  },
  titleImage: {
    position: "absolute",
    top: "-46px",
    right: "70px",
    width: "430px",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      width: "322px",
      top: "0px",
      right: "0px",
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const BottomBanner = ({
  iconPrimary,
  primary,
  secondary,
  secondarySub,
  color,
  bgcolor,
  backgroundColor,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <Grid className={classes.bannerOuterLayer}>
      <Card
        className={classes.mainCard}
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
        }}
      >
        <CardContent
          className={classes.cardContent}
          sx={{
            height: "100%",
            padding: "0px",
            paddingTop: "20px",
            paddingBottom: "10px !important",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            height={"100%"}
            sx={{
              flexDirection: {
                xs: "column-reverse",
                md: "row",
              },
            }}
          >
            <Grid item xs={12} md={6}>
              <Box className={classes.layoutPadding}>
                <Typography className={classes.textPrimary}>
                  Download Pravasi Vayana Mobile app
                </Typography>
                <Grid container spacing={1}>
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.btnBanner}
                    startIcon={
                      <Box
                        component={"img"}
                        alt="image"
                        src={android}
                        width={{ xs: "23px", md: "34px" }}
                      />
                    }
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=io.hashinclude.pravasivayana",
                        "_blank"
                      );
                    }}
                  >
                    <Box sx={{ textAlign: "left" }}>
                      <Typography sx={{ fontSize: { xs: "10px", md: "14px" } }}>
                        Get it on
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "24px" },
                          fontWeight: "600",
                        }}
                      >
                        Google play
                      </Typography>
                    </Box>
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    className={classes.btnBanner}
                    startIcon={
                      <Box
                        component="img"
                        alt="image"
                        src={ios}
                        width={{ xs: "23px", md: "34px" }}
                      />
                    }
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/app/pravasivayana/id6504276766",
                        "_blank"
                      );
                    }}
                  >
                    <Box sx={{ textAlign: "left" }}>
                      <Typography sx={{ fontSize: { xs: "10px", md: "14px" } }}>
                        Download on the
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: { xs: "12px", md: "24px" },
                          fontWeight: "600",
                        }}
                      >
                        App store
                      </Typography>
                    </Box>
                  </Button>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={0} md={6}>
              <Grid
                sx={{
                  display: { xs: "block", md: "block" },
                }}
              >
                <img
                  className={classes.titleImage}
                  alt="image"
                  src={bannerDevice}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

BottomBanner.propTypes = {
  iconPrimary: PropTypes.object,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  secondarySub: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  bgcolor: PropTypes.string,
};
