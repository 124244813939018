import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { Grid, Typography, Button, Box, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
// style constant
const useStyles = makeStyles((theme) => ({
  primary: {
    "&.MuiTypography-root": {
      color: "#373737",
      fontSize: "16px",
      fontStyle: "normal",
      fontWieght: "700 !important",
      lineHeight: "24px",
      letterSpacing: " 0.32px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  primaryBox: {
    "&.MuiBox-root": {
      background: "#FAFAFA",
      borderRadius: "6px",
      padding: "20px",
      display: "flex",
      // width: '100%',
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.common.primary,
  textTransform: "none",
  fontSize: 20,
  width: "fit-content",
  fontWeight: 700,
  boxShadow: "none",
  "&:hover": {
    backgroundColor: theme.palette.common.primaryHover,
  },
}));

export const DownloadBtn = ({ caption_text, url }) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box className={classes.primaryBox}>
          <Typography variant="body2" align="left" className={classes.primary}>
            <span>{caption_text}</span>
          </Typography>
          <ColorButton
            variant="contained"
            disableElevation
            disableRipple
            sx={{ fontSize: { xs: "15px", sm: "17px" }, borderRadius: "8px" }}
            onClick={() => {
              window.open(url, "_blank");
            }}
          >
            Open
          </ColorButton>
        </Box>
      </Grid>
    </Grid>
  );
};

DownloadBtn.propTypes = {
  caption_text: PropTypes.string,
  url: PropTypes.string,
};
