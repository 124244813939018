import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  Avatar,
} from "@mui/material";

// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      backgroundColor: "#F6F6F6",
      height: "100%",
      boxShadow: "none",
      width: 315,
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "transparent",
        width: 172,
      },
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "16px !important",
        [theme.breakpoints.down("sm")]: {
          padding: "0px !important",
        },
      },
    },
  },
  sideIcon: {
    "& > svg": {
      width: "32px",
      height: "32px",
    },
  },
  premium: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: 600,
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      height: "50px",
    },
  },
  primaryTextBlock: {
    "&.MuiTypography-root": {
      fontSize: "12px",
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "10px",
      },
    },
  },
  primaryText: {
    "&.MuiTypography-root": {
      marginLeft: "8px",
      fontSize: "14px",
      fontWeight: 400,
      alignItems: "center",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
  },
  cardImage: {
    width: "-webkit-fill-available",
    padding: " 0px",
    borderRadius: "5px;",
    height: 170,
    [theme.breakpoints.down("sm")]: {
      height: 120,
    },
    objectFit: "cover",
  },
  headerAvatar: {
    "&.MuiAvatar-root": {
      width: "35px",
      height: "35px",
      [theme.breakpoints.down("md")]: {
        width: "24px",
        height: "24px",
      },
    },
  },
  profileChip: {
    height: "48px",
    alignItems: "center",
    borderRadius: "27px",
    transition: "all .2s ease-in-out",
  },
  badgeCard: {
    borderRadius: "6px",
    marginLeft: "15px",
    width: "fit-content",
    padding: 10,
    padding: "4px",
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const FeaturedArticleCard = ({
  iconPrimary,
  primary,
  secondary,
  secondarySub,
  color,
  bgcolor,
  backgroundColor,
  authorImage,
  onCardClick,
  onClickAuthor,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary /> : null;

  const handleAuthorClick = (event) => {
    event.stopPropagation(); // Prevents the event from bubbling up to the Link
    event.preventDefault();
    onClickAuthor();
  };

  return (
    <Card className={classes.sideIconCard}>
      <CardContent className={classes.sideCardContent}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          sx={{ cursor: "pointer" }}
          onClick={onCardClick}
        >
          <Grid item xs={12} className={classes.sideIcon}>
            <img className={classes.cardImage} alt="image" src={iconPrimary} />
          </Grid>
          <Grid item sm={12}>
            <Grid
              item
              style={{
                backgroundColor: backgroundColor,
              }}
              className={classes.badgeCard}
            >
              <Typography
                variant="body2"
                align="left"
                className={classes.primaryTextBlock}
                sx={{ color: bgcolor ? "#fff" : "grey.700" }}
              >
                <span style={{ color }}>{secondarySub}</span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Typography
              variant="h6"
              className={classes.premium}
              sx={{ color: bgcolor ? "#fff" : "" }}
            >
              {primary}
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            sx={{ display: "flex", alignItems: "center" }}
            onClick={handleAuthorClick}
          >
            <Avatar
              src={authorImage}
              className={classes.headerAvatar}
              aria-haspopup="true"
              color="inherit"
            />

            <Typography
              variant="body2"
              align="left"
              className={classes.primaryText}
              sx={{ color: bgcolor ? "#fff" : "grey.700" }}
            >
              {secondary}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

FeaturedArticleCard.propTypes = {
  iconPrimary: PropTypes.object,
  authorImage: PropTypes.object,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  secondarySub: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  bgcolor: PropTypes.string,
  onCardClick: PropTypes.func,
  handleAuthorClick: PropTypes.func,
};
