import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
//---------------components -------->
import { LoginWrapper, PhoneTextField } from "../../components";
import { api, urls } from "../../services";
import { useToast } from "../../contexts";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

const useStyles = makeStyles((theme) => ({
  titleText: {
    "&.MuiTypography-root": {
      fontWeight: 700,
      fontSize: "28px",
      color: "#1A1A1A",
    },
  },
  titleTextSub: {
    "&.MuiTypography-root": {
      fontWeight: 600,
      fontSize: 16,
      color: "#000",
      marginBottom: "10px",
    },
  },
}));

const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: theme.palette.common.primary,
  textTransform: "none",
  fontSize: 16,
  fontWeight: 700,
  padding: "10px 16px !important",
  height: "52px",
  "&:hover": {
    backgroundColor: theme.palette.common.primaryHover,
  },
}));

export function SignIn() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    phone_code: "",
    phone_number: "",
    errors: { phone_code: false, phone_number: false },
  });
  const { showToast } = useToast();
  const [phoneInput, setPhoneInput] = useState("");

  const updateFormData = (key, value) => {
    setFormData((prev) => {
      const curr = { ...prev };
      curr[key] = value;
      curr.errors[key] = false;
      // console.log("curr",curr)
      // console.log("countries",countries)

      return curr;
    });
  };

  const handleGetClick = () => {
    let invalid = false;
    if (!formData.phone_code || !formData.phone_number) {
      setFormData((prev) => {
        const curr = { ...prev };
        prev.errors.phone_code = true;
        prev.errors.phone_number = true;
        return curr;
      });
      invalid = true;
    }

    if (!invalid) {
      const params = { ...formData };
      delete params.errors;
      api
        .post(urls.sendOTP, params)
        .then((res) => {
          // console.log("res",res)
          // console.log("msg", res.data.message)
          showToast(res.data.message, "success");

          navigate("/otp-verification", {
            replace: true,
            state: {
              phone_code: formData.phone_code,
              phone_number: formData.phone_number,
            },
          });
        })
        .catch((err) => {
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  const handlePhoneInputChange = (value) => {
    setPhoneInput(value);
    let items = value.split(" ");
    let code = items[0];
    let phone = items.slice(1, items.length).join("");
    setFormData((prev) => ({ ...prev, phone_code: code, phone_number: phone }));
  };

  return (
    <LoginWrapper>
      <Typography
        className={classes.titleText}
        gutterBottom
        variant="h5"
        component="div"
      >
        Login
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            className={classes.titleTextSub}
            variant="subtitle1"
            component="div"
          >
            Enter Your Mobile Number
          </Typography>
          {/* <PhoneTextField
            id="phone"
            label="Phone Number"
            required={true}
            value={null}
            error={formData.errors.phone_number || formData.errors.phone_code}
            onChange={(e) => updateFormData("phone_number", e.target.value)}
            codeOptions={[
              {
                id: "fdcd81dc-fdc7-4ad0-9bc3-bdcef4104eda",
                name: "India",
                flag: "U+1F1EE U+1F1F3",
                phone_code: "91",
              },
            ]}
            // codeValue="91"
            onCodeChange={(e, v) => updateFormData("phone_code", v.phone_code)}
          /> */}
          <MuiTelInput
            label="Phone Number"
            sx={{ width: "100%" }}
            defaultCountry="IN"
            value={phoneInput}
            onChange={handlePhoneInputChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontWeight: 400, fontSize: 16 }}
          >
            OTP will be sent to this number
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ColorButton
            variant="contained"
            disableElevation
            fullWidth
            disableRipple
            onClick={handleGetClick}
            disabled={!matchIsValidTel(phoneInput)}
          >
            Get OTP
          </ColorButton>
        </Grid>
      </Grid>
    </LoginWrapper>
  );
}
// ==============================|| Login PAGE ||============================== //
