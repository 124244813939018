import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { Button, Typography, Grid, Stack, useTheme } from "@mui/material";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { Ellipse } from "../../assets";
import { Navigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardTop: {
    borderRadius: 6,
  },
  cardLayout: {
    "&.MuiCard-root": {
      borderRadius: "3px",
      boxShadow: "none",
      background: "transparent",
    },
    cursor: "pointer",
  },
  cardImage: {
    boxShadow: "none",
    width: "100%",
    // height: "235px",
    [theme.breakpoints.down("md")]: {
      height: "161px",
    },
    aspectRatio: "3/4",
  },
  cardTitle: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      // marginTop: "20px",
      textAlign: "center",
      fontWeight: "700",
      lineHeight: "20px",
      marginBottom: "10px",
      color: " #000",
      [theme.breakpoints.down("md")]: {
        marginTop: "12px",
        fontSize: "14px",
      },
    },
  },
  cardSubTitle: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      textAlign: "center",
      fontWeight: "600",
      lineHeight: "20px",
      color: theme.palette.common.primary,
      [theme.breakpoints.down("md")]: {
        fontSize: "13px",
      },
    },
  },
  imageContainer: {
    // margin: '-88px 0 0 auto',
  },
  EllipseImage: {
    width: "-webkit-fill-available",
    // marginTop: "10px",
  },
}));

//---------------customised checkbox --------->
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "0px 5px 0px 5px",
  width: 16,
  height: 16,
  padding: "0 !important",
  boxShadow: "none",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "0px auto #00C058",
    outlineOffset: 0,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#00C058",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#00C058",
  },
});

// Inspired by blueprintjs
function BpCheckbox(props) {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}

const ColorButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.common.primary,
  textTransform: "none",
  fontSize: 13,
  fontWeight: 700,
  "&:hover": {
    backgroundColor: theme.palette.common.primaryHover,
  },
}));

export function ImageWithGreenTick({ image, isSelected }) {
  const theme = useTheme();
  return (
    <Stack sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <img
          alt="Magazine Image"
          src={image}
          style={{
            width: "100%",
            aspectRatio: 3 / 4,
            borderRadius: "6px",
            objectFit: "cover",
            boxShadow: "none",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            width: { xs: 20, md: 25 },
            height: { xs: 20, md: 25 },
            backgroundColor: "#00C058",
            borderRadius: "2px 8px 2px 8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DoneRoundedIcon
            sx={{
              width: { xs: 15, md: 20 },
              height: { xs: 15, md: 20 },
              color: "white",
            }}
          ></DoneRoundedIcon>
        </Box>
      </Box>
    </Stack>
  );
}

export const MagazineCard = ({ date, name, image, bgcolor, year }) => {
  const classes = useStyles();
  return (
    <Card className={classes.cardLayout}>
      <CardContent
        className={classes.cardTop}
        sx={{
          padding: "20px",
          position: "relative",
          backgroundColor: bgcolor ? bgcolor : "white",
        }}
      >
        <Box className={classes.imageContainer}>
          <ImageWithGreenTick image={image} isSelected={true} />
          <Box
            component={"img"}
            className={classes.EllipseImage}
            alt="Magazine Image"
            src={Ellipse}
          />
        </Box>
        <Typography className={classes.cardTitle}>{date}</Typography>
        <Typography
          className={classes.cardSubTitle}
        >{`പുസ്തകം ${name} - ${year}`}</Typography>
      </CardContent>
    </Card>
  );
};
MagazineCard.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string,
  image: PropTypes.string,
  bgcolor: PropTypes.string,
};
