import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import LoaderCard from "./cards/LoaderCard";
import { useLocalStorage } from "../hooks";
import { useNavigate } from "react-router-dom";

const TokenExtractor = () => {
  const { setItem } = useLocalStorage();
  const { id, token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setItem("access_token", token);
    window.location.replace(`/answer-quiz/${id}`);
  }, []);
  return (
    <Box>
      <LoaderCard />
    </Box>
  );
};

export default TokenExtractor;
