import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Avatar,
  Card,
  Button,
  CardContent,
  Typography,
  Box,
  Stack,
  Divider,
  alpha,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TitleBar, Notification, BreadCrumbList } from "../../components";
import { EditProfile } from "./EditProfile";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import { useAuth, useToast } from "../../contexts";
import { api, urls } from "../../services";
import moment from "moment";
import pluralize from "pluralize";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  right: {
    display: "block",
    justifyContent: "end",
  },
  Text: {
    "&.MuiTypography-root": {
      color: "white",
      fontSize: "18px !important",
      fontWeight: "400 !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },
  },
  subText: {
    "&.MuiTypography-root": {
      color: "#7C7C7C",
      fontSize: "14px !important",
      fontWeight: "400 !important",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px !important",
      },
    },
  },
  box: {
    p: 1,
    background: "#2D2D2D",
    padding: 20,
    borderRadius: 10,
  },
  boxBtn: {
    background: alpha(theme.palette.common.primary, 0.1),
    padding: 10,
    color: theme.palette.common.primary,
    fontWeight: 700,
    fontFamily: [
      "Urbanist",
      "Baloo Chettan 2",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontSize: 14,
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  cardHeading: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  cardSubHeading: {
    fontSize: "16px !important",
    fontWeight: "600 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px !important",
    },
  },
  cardHeader: {
    "&.MuiCardHeader-root": {
      borderBottom: "1px solid #d6d6d6",
      "&.MuiCardHeader-title": {
        fontSize: "18px !important",
        fontWeight: "600 !important",
        fontFamily: "Urbanist !important",
      },
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },
  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    background: "#F6F6F6",
    minHeight: "70vh",
  },
  editbtn: {
    color: `${theme.palette.common.primary} !important`,
    textTransform: "capitalize !important",
    fontSize: "16px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },
}));

//<!-----------------------sample Data------------------>

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function MyAccount() {
  const classes = useStyles();
  const { userData, logout, updateName } = useAuth();
  const { showToast } = useToast();
  const [activePlans, setActivePlans] = useState([]);
  const [notifications, setNotifications] = useState([]);

  //Modal ----------------------------------------------
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  //Modal ----------------------------------------------

  function stringAvatar(name) {
    if (!name) return "- -"; // Return "NA" for an empty string
    const names = name.split(" ");
    if (names.length === 1) return `${names[0].charAt(0)}`; // Handle single word
    return `${names[0].charAt(0)}${names[1].charAt(0)}`; // Handle multiple words
  }

  const getActivePlans = () => {
    api
      .get(urls.activePlans)
      .then((res) => {
        setActivePlans(res.data.data?.active_plans ?? []);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  const getNotifications = () => {
    api
      .get(urls.notifications)
      .then((res) => {
        setNotifications(res.data.data?.notifications ?? []);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getActivePlans();
    getNotifications();
  }, []);

  const getDuration = (days) => {
    switch (days) {
      case 365:
        return "Yearly";
      case 180:
        return "Half Yearly";
      case 30:
        return "Monthly";
      default:
        return "-";
    }
  };

  const getFromToDate = (start_date, end_date) => {
    let start = moment(start_date, "YYYY-MM-DD");
    let end = moment(end_date, "YYYY-MM-DD");
    return `From ${start.format("DD MMM YYYY")} to ${end.format(
      "DD MMM YYYY"
    )}`;
  };

  const getRemaining = (end_date) => {
    let endString = `${end_date} 23:59:59`;
    let end = moment(endString, "YYYY-MM-DD hh:mm:ss");
    let result = moment.duration(end.diff(moment.now())).asDays();
    return `${result.toFixed(0)}`;
  };

  const updateProfile = (name) => {
    handleClose();
    api
      .post(urls.editProfile, { first_name: name })
      .then((res) => {
        showToast(res.data.message, "success");
        updateName(name);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  return (
    <>
      <Grid container className={classes.containerSecound}>
        <Container maxWidth="xl" className={classes.root}>
          <BreadCrumbList pathnames={[{ text: "My Account", link: "" }]} />
          <Grid
            container
            className={classes.container}
            spacing={2}
            direction="row"
          >
            <Grid item xs={12}>
              <TitleBar Title="My Account" />
            </Grid>
            <Grid item md={7} xs={12}>
              <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                  <Box sx={{ p: 3, bgcolor: "white", borderRadius: 3 }}>
                    <Stack
                      spacing={2}
                      direction="row"
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Stack spacing={2} direction="row" display={"flex"}>
                        <Avatar
                          sx={{
                            bgcolor: "black",
                            height: "57px",
                            width: "57px",
                          }}
                          aria-label="recipe"
                        >
                          {stringAvatar(userData?.first_name ?? "- -")}
                        </Avatar>

                        <Stack direction={"column"} spacing={0}>
                          <Typography
                            className={classes.cardHeading}
                            component="div"
                          >
                            {userData?.first_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className={classes.cardSubHeading}
                          >
                            Phone:{" "}
                            {`${userData?.phone_code} ${userData?.phone_number}`}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className={classes.cardSubHeading}
                          >
                            Email: {userData?.email}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className={classes.cardSubHeading}
                          >
                            Country: {userData?.country}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className={classes.cardSubHeading}
                          >
                            State: {userData?.state}
                          </Typography>

                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className={classes.cardSubHeading}
                          >
                            DOB: {userData?.dob}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className={classes.cardSubHeading}
                          >
                            Religion: {userData?.religion}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            className={classes.cardSubHeading}
                          >
                            Occupation: {userData?.occupation}
                          </Typography>
                        </Stack>
                      </Stack>

                      <Stack>
                        <Button
                          size="small"
                          className={classes.editbtn}
                          onClick={handleClickOpen}
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} xs={12}>
              <Card elevation={0} sx={{ borderRadius: 3 }}>
                <Typography
                  sx={{
                    pr: 3,
                    pl: 3,
                    pt: 3,
                    fontFamily: "Urbanist",
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                  color="text.primary"
                >
                  {notifications.length > 0
                    ? `Notifications (${notifications.length})`
                    : "Notifications"}
                </Typography>
                {notifications.length === 0 && (
                  <Typography
                    sx={{
                      pr: 3,
                      pl: 3,
                      pt: 3,
                      fontFamily: "Urbanist",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                    color="text.primary"
                  >
                    No Data Found
                  </Typography>
                )}
                <CardContent
                  sx={{
                    maxHeight: "60vh",
                    overflowY: "auto",
                  }}
                >
                  {notifications.map((notif, i) => (
                    <Notification
                      key={i}
                      iconPrimary={notif.image}
                      primary={notif.title}
                      secondarySub={notif.message}
                      secondary={moment(notif.timestamp).fromNow()}
                    />
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xs"
        // sx={{ maxWidth: '304px'}}
      >
        <EditProfile
          name={userData?.first_name}
          email={userData?.email}
          onCancelClick={handleClose}
          onSaveClick={updateProfile}
        />
      </BootstrapDialog>
    </>
  );
}
