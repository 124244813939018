import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import {
  Container,
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
  Divider,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
} from "@mui/material";
import { logo2, trophy } from "../../assets";
import { HeaderButton } from "../../components";
import { urls } from "../../services";
import LoaderCard from "../../components/cards/LoaderCard";
import { useToast } from "../../contexts";
import { api } from "../../services";
import { useLocalStorage } from "../../hooks";

export const AnswerQuiz = (props) => {
  const { id } = useParams();
  const theme = useTheme();
  const { showToast } = useToast();
  const [quizData, setQuizData] = useState(null);
  const [quizMessage, setQuizMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [submittingQuiz, setSubmittingQuiz] = useState(false);
  const [isResultPublished, setIsResultPublished] = useState(null);
  const [winnersData, setWinnersData] = useState(null);
  const { setItem } = useLocalStorage();

  const getQuizData = () => {
    setLoading(true);
    setQuizMessage("");
    setQuizData(null);
    api
      .get(urls.getQuiz + id)
      .then((res) => {
        setQuizData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setQuizMessage(err.response?.data?.message);
        setLoading(false);
      });
  };

  const getWinnersResult = () => {
    setLoading(true);
    setQuizMessage("");
    setQuizData(null);
    api
      .get(urls.getQuizWinners + id)
      .then((res) => {
        let data = res.data.data;
        console.log("🚀 ~ .then ~ data:", data);
        setWinnersData(data);
        setLoading(false);
      })
      .catch((err) => {
        setQuizMessage(err.response?.data?.message);
        setLoading(false);
      });
  };

  const getQuizStatus = () => {
    setLoading(true);
    api
      .get(urls.getQuizStatus + id)
      .then((res) => {
        let data = res.data.data;
        if (data?.quiz_result_published) {
          setIsResultPublished(true);
          //get winners list
          getWinnersResult();
        } else {
          setIsResultPublished(false);
          //not published (either attended or non attended)
          getQuizData();
        }
      })
      .catch((err) => {
        setQuizMessage(err.response?.data?.message);
        setLoading(false);
      });
  };

  const getData = () => {
    setLoading(true);
    api
      .get(urls.getProfile)
      .then((res) => {
        let data = res.data.data;
        console.log("🚀 ~ .then ~ data:", data);
        setItem("user_data", JSON.stringify(data.user_details));

        getQuizStatus();
      })
      .catch((err) => {
        setQuizMessage("Something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      getData();
    }, 2000);
  }, []);

  const handleSubmit = () => {
    let totCount = quizData.questions.length;
    let ansCount = quizData.questions.filter(
      (q) => q.selectedAnswer !== undefined && q.selectedAnswer !== null
    ).length;

    if (ansCount !== totCount) {
      showToast("Please answer all questions", "error");
    } else {
      let params = [];

      quizData.questions.forEach((q) => {
        params.push({
          question_id: q.id,
          answer: q.options[q.selectedAnswer].answer,
        });
      });
      setSubmittingQuiz(true);
      api
        .post(urls.answerQuiz + id, params)
        .then((res) => {
          setSubmittingQuiz(false);
          setQuizMessage("Quiz submitted successfully");
        })
        .catch((err) => {
          setSubmittingQuiz(false);
          showToast(err.response?.data?.message, "error");
        });
    }
  };

  return (
    <Box sx={{ bgcolor: "common.greyBg", minHeight: "100vh" }}>
      <Container maxWidth="md" sx={{ p: 3 }}>
        {loading && <LoaderCard />}
        {!loading && quizMessage === "" && !isResultPublished && (
          <Paper sx={{ minHeight: 300, p: 3 }}>
            <Stack alignItems={"center"} spacing={2}>
              <Box>
                <img alt="logo" src={logo2} />
              </Box>
              <Box
                sx={{
                  bgcolor: "common.blueBg",
                  padding: 2,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <Box
                    component={"img"}
                    sx={{
                      boxShadow: "none",
                      width: "80px",
                      aspectRatio: "3/4",
                      borderRadius: "8px",
                    }}
                    alt="Magazine Image"
                    src={quizData?.magazine?.image}
                  />
                  <Stack
                    spacing={0}
                    direction={"column"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: 600,
                        fontSize: { md: "30px", sm: "22px" },
                      }}
                    >
                      {quizData?.magazine?.issue_name}
                    </Typography>
                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: 400,
                        fontSize: { md: "20px", sm: "16px" },
                      }}
                    >
                      QUIZ NO. {quizData?.quiz_name}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <Divider sx={{ width: "100%" }} />
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "left",
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                Select Answers
              </Typography>
              {quizData?.questions.map((question, index) => (
                <Stack
                  key={index}
                  sx={{ width: "100%" }}
                  alignItems={"flex-start"}
                >
                  <Typography
                    sx={{ width: "100%", fontWeight: 500 }}
                    variant="body1"
                  >
                    {`${index + 1}. ${question.question}`}
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      onChange={(e) => {
                        setQuizData({
                          ...quizData,
                          questions: [
                            ...quizData.questions.slice(0, index),
                            {
                              ...quizData.questions[index],
                              selectedAnswer: parseInt(e.target.value),
                            },
                            ...quizData.questions.slice(index + 1),
                          ],
                        });
                      }}
                      value={quizData?.questions[index].selectedAnswer}
                    >
                      {question.options.map((opt, index) => (
                        <FormControlLabel
                          key={index}
                          value={index}
                          control={<Radio />}
                          label={opt.answer}
                          sx={{ m: "-4px" }}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Stack>
              ))}
              <Box>
                <HeaderButton
                  type="primary"
                  disabled={submittingQuiz}
                  onClick={handleSubmit}
                >
                  Submit
                </HeaderButton>
              </Box>
            </Stack>
          </Paper>
        )}
        {!loading && quizMessage === "" && isResultPublished && (
          <Paper sx={{ minHeight: 300, p: 3 }}>
            <Stack alignItems={"center"} spacing={2}>
              <Box>
                <img alt="logo" src={logo2} />
              </Box>
              <Box
                sx={{
                  bgcolor: "common.blueBg",
                  padding: 2,
                  borderRadius: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack direction={"row"} spacing={1}>
                  <Box
                    component={"img"}
                    sx={{
                      boxShadow: "none",
                      width: "80px",
                      aspectRatio: "3/4",
                      borderRadius: "8px",
                    }}
                    alt="Magazine Image"
                    src={winnersData?.magazine_image}
                  />
                  <Stack
                    spacing={0}
                    direction={"column"}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: 600,
                        fontSize: { md: "30px", sm: "22px" },
                      }}
                    >
                      {winnersData?.magazine_issue_name}
                    </Typography>
                    <Typography
                      sx={{
                        width: "100%",
                        fontWeight: 400,
                        fontSize: { md: "20px", sm: "16px" },
                      }}
                    >
                      QUIZ NO. {winnersData?.quiz_name}
                    </Typography>
                  </Stack>
                </Stack>
              </Box>

              <Divider sx={{ width: "100%" }} />
              <Typography
                sx={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: 18,
                }}
              >
                Winners
              </Typography>
              {winnersData?.winners_list?.length > 0 && (
                <List disablePadding sx={{ width: "100%" }}>
                  {winnersData?.winners_list?.map((item, i) => (
                    <Fragment>
                      <ListItem key={i}>
                        <Stack sx={{ width: "100%" }}>
                          <Typography sx={{ fontSize: 18 }}>
                            {item.user_name}
                          </Typography>
                          <Typography>{item.country}</Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={1}
                        >
                          <Typography
                            sx={{ fontSize: 20 }}
                          >{`${item.correct_answers_count}/${item.questions_count}`}</Typography>
                          <img style={{ height: 22 }} src={trophy} alt="icon" />
                        </Stack>
                      </ListItem>
                      {i + 1 !== winnersData?.winners_list.length && (
                        <Divider />
                      )}
                    </Fragment>
                  ))}
                </List>
              )}
            </Stack>
          </Paper>
        )}
        {!loading && quizMessage !== "" && (
          <Paper
            sx={{
              minHeight: 200,
              p: 3,
              display: "flex",
              // alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack alignItems={"center"} spacing={2}>
              <Box>
                <img alt="logo" src={logo2} />
              </Box>
              <Typography
                sx={{
                  width: "100%",
                  fontWeight: 500,
                  fontSize: "24px",
                  textAlign: "center",
                }}
                variant="body1"
              >
                {quizMessage}
              </Typography>
              <HeaderButton
                type="primary"
                disabled={submittingQuiz}
                onClick={() => {
                  window.location.replace(`/home`);
                }}
              >
                Back to Home
              </HeaderButton>
            </Stack>
          </Paper>
        )}
      </Container>
    </Box>
  );
};

AnswerQuiz.propTypes = {};
