// import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/styles";
import {
  Typography,
  Avatar,
  Box,
  Stack,
  IconButton,
  Slider,
} from "@mui/material";

import React, { useState, useRef } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

// =============================|| SIDE ICON CARD ||============================= //

export const Audio = ({ url, author }) => {
  const theme = useTheme();

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [mute, setMute] = useState(false);
  const [progress, setProgress] = useState(0);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;
    const calculatedProgress = (currentTime / duration) * 100;
    setProgress(calculatedProgress);
  };

  const handleSeek = (_, newValue) => {
    const newTime = (newValue * audioRef.current.duration) / 100;
    audioRef.current.currentTime = newTime;
    setProgress(newValue);
  };
  const buttonStyle = () => ({
    color: "#fff",
    background: theme.palette.common.primary,
    position: "inherit",
    borderRadius: "50%",
    padding: "inherit",
    minWidth: "inherit",
    width: 40,
    height: 40,
    "&:hover": {
      backgroundColor: theme.palette.common.primary,
    },
  });

  return (
    <Box sx={{ p: 1 }}>
      <Stack
        sx={{ p: 2, backgroundColor: "black", borderRadius: 2 }}
        direction="column"
        spacing={2}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Avatar alt="avt" sx={{ width: 40, height: 40 }} src={author.image} />
          <Stack spacing={2}>
            <Typography sx={{ color: "white" }}>{author.name}</Typography>
          </Stack>
        </Stack>

        <Stack
          direction="row"
          sx={{ width: "100%", display: "flex", alignItems: "center" }}
          spacing={1}
        >
          <IconButton sx={buttonStyle} onClick={togglePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton onClick={() => setMute((prev) => !prev)}>
            {mute ? (
              <VolumeOffIcon sx={{ color: "white" }} />
            ) : (
              <VolumeUpIcon sx={{ color: "white" }} />
            )}
          </IconButton>
          <Stack direction="row" spacing={2} alignItems="center" width="100%">
            <Typography sx={{ color: "white", fontWeight: 600 }}>
              {formatTime(audioRef.current ? audioRef.current.currentTime : 0)}
            </Typography>
            <Slider
              value={progress}
              onChange={handleSeek}
              aria-labelledby="continuous-slider"
              sx={{ color: "white" }}
            />
            <Typography
              sx={{
                color: "white",
                fontWeight: 600,
                display: { xs: "none", sm: "block" },
              }}
            >
              {formatTime(audioRef.current ? audioRef.current.duration : 0)}
            </Typography>
          </Stack>
          <audio
            ref={audioRef}
            muted={mute}
            src={url}
            style={{ display: "none" }}
            onTimeUpdate={handleTimeUpdate}
            onEnded={() => setIsPlaying(false)}
          />
        </Stack>
      </Stack>
    </Box>
  );
};

Audio.propTypes = {
  url: PropTypes.string,
};
