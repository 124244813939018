import { Container, Grid, Typography, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ArticleCard, TitleBar, BreadCrumbList } from "../../components";
import { useParams, useNavigate } from "react-router-dom";
import { api, urls } from "../../services";
import { useAuth, useToast } from "../../contexts";
import { useEffect, useState } from "react";
import LoaderCard from "../../components/cards/LoaderCard";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
    },
  },

  containerSecound: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingRight: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
    },
    background: "#F6F6F6",
    minHeight: "92vh",
  },
}));

//<!-----------------------sample Data------------------>

export function CategoryDetailsList() {
  const classes = useStyles();
  const { catID } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const { logout } = useAuth();
  const [detailsData, setDetailsData] = useState(null);
  const [apiCalling, setApiCalling] = useState(false);

  const getCategoryDetails = () => {
    api
      .get(`${urls.categoryDetails}${catID}?page=1&limit=100`)
      .then((res) => {
        setDetailsData(res.data.data);
        setApiCalling(false);
      })
      .catch((err) => {
        setApiCalling(false);
        if (err.response.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    setApiCalling(true);
    getCategoryDetails();
  }, [catID]);

  return (
    <Grid container className={classes.containerSecound}>
      <Container maxWidth="xl" className={classes.root}>
        <BreadCrumbList pathnames={[{ text: "Categories", link: "" }]} />
        <Grid
          container
          className={classes.container}
          spacing={2}
          direction="row"
        >
          <Grid item md={12} xs={12} sm={12}>
            <TitleBar
              Title={
                detailsData?.article_category?.descriptions[0]?.category_name
              }
            />
          </Grid>
          {apiCalling && (
            <Grid item md={12} xs={12} sm={12}>
              <LoaderCard />
            </Grid>
          )}
          {!apiCalling && detailsData?.articles?.length == 0 && (
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                No Article found
              </Typography>
            </Grid>
          )}
          {detailsData?.articles?.map((post, i) => (
            <Grid item md={4} key={i}>
              <ArticleCard
                iconPrimary={post.feature_image}
                primary={post.title}
                secondary={post.author.name}
                authorImage={post.author.image}
                secondarySub={
                  post.article_categories[0].descriptions[0].category_name
                }
                color={post.article_categories[0].category_colour}
                backgroundColor={alpha(
                  post.article_categories[0].category_colour,
                  0.1
                )}
                onCardClick={() => {
                  navigate(`/article-details/${post.id}`);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  );
}
