import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  alpha,
} from "@mui/material";

// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none !important",
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "10px !important",
        [theme.breakpoints.down("sm")]: {
          padding: "0px !important",
        },
      },
    },
  },
  primary: {
    "&.MuiTypography-root": {
      color: theme.palette.common.primary,
      fontFamily: "Baloo Chettan 2",
      fontSize: "22px",
      fontStyle: "italic",
      fontWeight: "700",
      lineHeight: "34px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  primaryPost: {
    "&.MuiTypography-root": {
      color: "black",
      fontFamily: "Baloo Chettan 2",
      fontSize: "20px",
      fontStyle: "italic",
      fontWeight: "700",
      lineHeight: "34px",
      padding: "4px",
      backgroundColor: alpha(theme.palette.common.primary, 0.1),
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  secondary: {
    "&.MuiTypography-root": {
      color: "#565656",
      fontFamily: "Baloo Chettan 2",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "47px",
      // marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  secondaryCard: {
    padding: "7px",
    borderRadius: "8.727px",
    background: "#EAEAEA",
  },
  cardImage: {
    width: "100%",
    // width: "-webkit-fill-available",
    padding: " 0px",
    borderRadius: "5px;",
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const Quote = ({ primary, secondary, postPrimary }) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Card className={classes.sideIconCard}>
      <CardContent className={classes.sideCardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Stack direction={"column"} spacing={2}>
              {secondary !== "" && (
                <Typography variant="h6" className={classes.secondary}>
                  <span className={classes.secondaryCard}>{secondary}</span>
                </Typography>
              )}
              {primary !== "" && (
                <Typography variant="body1" className={classes.primary}>
                  {primary.split("\n").map((line, index, array) => (
                    <React.Fragment key={index}>
                      {line}
                      {index < array.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </Typography>
              )}
              {postPrimary !== "" && (
                <Typography variant="h6" className={classes.primaryPost}>
                  {postPrimary}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Quote.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
  postPrimary: PropTypes.string,
};
