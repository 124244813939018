import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2", // Your primary color
    },
    secondary: {
      main: "#F57C00", // Your secondary color
    },
    tertiary: {
      main: "#388E3C", // Your tertiary color
    },
    common: {
      black: "#000",
      white: "#fff",
      blackBg: "#1A1A1A",
      greyBg: "#F0F0F0",
      redBtn: "#E91F29",
      greyTxt: "#303030",
      greyBorder: "#D9D9D9",
      lightGreyTxt: "#656565",
      greenTxt: "#00C058",
      redTxt: "#EB363F",
      primary: "#0F8EFF",
      primaryHover: "#327FFA",
      blueBg: "#F6F9FF",
    },
    theme: {
      main: "#0F8EFF",
    },
    // Other color definitions...
  },
  typography: {
    fontFamily: [
      "Baloo Chettan 2",
      "Urbanist",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: [
            "Baloo Chettan 2",
            "Urbanist",
            "Helvetica",
            "Arial",
            "sans-serif",
          ].join(","),
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: [
            "Baloo Chettan 2",
            "Urbanist",
            "Helvetica",
            "Arial",
            "sans-serif",
          ].join(","),
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  },
  // Other theme settings...
});

export default theme;
