import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";

// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none !important",
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "10px !important",
        [theme.breakpoints.down("sm")]: {
          padding: "0px !important",
        },
      },
    },
  },
  primary: {
    "&.MuiTypography-root": {
      color: theme.palette.common.primary,
      fontFamily: "ML-TTNandini",
      fontSize: "22px",
      fontStyle: "italic",
      fontWeight: "700",
      lineHeight: "34px",
      borderRadius: "8px",
      background: "rgba(233, 31, 41, 0.06)",
      padding: "10px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  secondary: {
    "&.MuiTypography-root": {
      color: "#565656",
      fontFamily: "Baloo Chettan 2",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "47px",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
  secondaryCard: {
    padding: "7px",
    borderRadius: "8.727px",
    background: "#EAEAEA",
  },
  cardImage: {
    width: "100%",
    // width: "-webkit-fill-available",
    padding: " 0px",
    borderRadius: "5px;",
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const HighlightedQuote = ({ primary, secondary }) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Card className={classes.sideIconCard}>
      <CardContent className={classes.sideCardContent}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.primary}>
              {primary}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

HighlightedQuote.propTypes = {
  primary: PropTypes.string,
  secondary: PropTypes.string,
};
