import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  IconButton,
  Box,
} from "@mui/material";
import { quoteBanner } from "../../assets";
import Lottie from "lottie-react";
import animationData from "../../assets/images/banner_animation.json";
import EastIcon from "@mui/icons-material/East";
import { Quote } from "../Quote";
import Close from "@mui/icons-material/Close";

// style constant
const useStyles = makeStyles((theme) => ({
  mainCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "35px",
      boxShadow: "none !important",
      backgroundSize: "cover",
      overflow: "visible",
      height: "270px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        borderRadius: "40px",
      },
    },
  },
  textTitle: {
    "&.MuiTypography-root": {
      fontSize: "24px",
      color: "white",
      fontWeight: 400,
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        textAlign: "left",
      },
    },
  },
  textPrimary: {
    "&.MuiTypography-root": {
      fontSize: "32px",
      color: "white",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        textAlign: "left",
      },
    },
  },
  textSecondary: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      color: "white",
      fontWeight: 500,
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        textAlign: "left",
      },
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  },
  layoutPadding: {
    "&.MuiBox-root": {
      padding: "0px 0px 0px 30px",
      [theme.breakpoints.down("md")]: {
        padding: "20px",
      },
    },
  },
  btnBanner: {
    "&.MuiButton-root": {
      borderRadius: "6px",
      backgroundColor: "black",
      textTransform: "capitalize",
      margin: "8px",
      width: "230px",
      heigh: "65px",
      "&:hover": {
        backgroundColor: "black",
      },
      [theme.breakpoints.down("md")]: {
        width: "140px",
        heigh: "49px",
      },
    },
  },
  bannerOuterLayer: {
    padding: "50px 0px",
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      padding: "50px 0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px",
    },
  },
  titleImage: {
    position: "absolute",
    top: "-46px",
    right: "70px",
    width: "430px",
    [theme.breakpoints.down("md")]: {
      position: "relative",
      width: "322px",
      top: "0px",
      right: "0px",
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export default function QuoteBanner({ quoteData }) {
  const classes = useStyles();

  const theme = useTheme();
  const [openModal, setOpenModal] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  const handleClickClose = () => {
    setOpenModal(false);
  };

  return (
    <Fragment>
      <Grid className={classes.bannerOuterLayer}>
        <Card
          className={classes.mainCard}
          sx={{
            backgroundImage: `url(${quoteBanner})`,
            backgroundSize: "cover",
          }}
        >
          <CardContent
            className={classes.cardContent}
            sx={{
              height: "100%",
              padding: "0px",
              paddingTop: "20px",
              paddingBottom: "10px !important",
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              spacing={1}
              height={"100%"}
              sx={{
                flexDirection: {
                  md: "row",
                },
              }}
            >
              <Grid item xs={12} md={12}>
                <Box className={classes.layoutPadding}>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Stack spacing={1}>
                      <Typography className={classes.textTitle}>
                        A Quote For You
                      </Typography>
                      <Typography className={classes.textPrimary}>
                        {quoteData.title_name}
                      </Typography>
                      <Typography className={classes.textSecondary}>
                        {quoteData.main_quote}
                      </Typography>
                      <Box>
                        <Button
                          variant="contained"
                          endIcon={<EastIcon />}
                          disableElevation
                          sx={{
                            textTransform: "none",
                            color: "#006482",
                            bgcolor: "white",
                            marginTop: "8px",
                            height: "40px",
                            padding: "22px",
                            borderRadius: "8px",
                            ":hover": {
                              color: "#006482",
                              bgcolor: "white",
                            },
                          }}
                          onClick={() => setOpenModal(true)}
                        >
                          Read More
                        </Button>
                      </Box>
                    </Stack>
                    <Box
                      sx={{
                        width: "300px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Lottie {...defaultOptions} />
                    </Box>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {/* Quote popup */}
      <Dialog open={openModal} onClose={handleClickClose}>
        <IconButton
          sx={{ position: "absolute", marginX: "90%", marginTop: "20px" }}
          onClick={handleClickClose}
        >
          {/* <--------- */}
          <Close />
        </IconButton>
        <DialogTitle>
          <Typography
            variant="body1"
            sx={{ textAlign: "center", fontSize: "24px", fontWeight: 600 }}
          >
            {quoteData.title_name}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Quote
            secondary={quoteData.pre_quote}
            primary={quoteData.main_quote}
            postPrimary={quoteData.post_quote}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

QuoteBanner.propTypes = {
  iconPrimary: PropTypes.object,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  secondarySub: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  bgcolor: PropTypes.string,
};
