// API URLs
const urls = {
  // base url
  baseURL: {
    dev: "https://dev.pravasivayana.com/",
    live: "https://api.pravasivayana.com/",
  },

  // auth apis
  sendOTP: "api/auth/v1/send_otp",
  verifyOTP: "api/auth/v1/verify_otp",
  RegisterUser: "api/auth/v1/register_user",
  refreshToken: "api/auth/v1/refresh_token",
  getProfile: "api/magazine/v1/user_details",

  // main apis
  dashboard: "api/magazine/v1/main_dashboard",
  magazine: "api/magazine/v1/magazine_list?page=1&limit=100",
  trendingArticles: "api/magazine/v1/trending_article_list?page=1&limit=100",
  topArticles: "api/magazine/v1/top_articles_list?page=1&limit=100",
  featuredArticles: "api/magazine/v1/featured_article_list?page=1&limit=100",
  freeArticles:
    "api/magazine/v1/free_article_list?page=1&limit=100&filter_key=&search_key=",
  bookmarkedArticles:
    "api/magazine/v1/bookmarked_articles_list?page=1&limit=100",
  bookmark: "api/magazine/v1/bookmark",
  magazineDetails: "api/magazine/v1/magazine_details/",
  authorDetails: "api/magazine/v1/author_details/",
  addFeedback: "api/magazine/v1/add_feedback",
  articleDetails: "api/magazine/v1/article_details/",
  categories: "api/magazine/v1/category_list?page=1&limit=100",
  plans: "api/magazine/v1/subscription_plan_list",
  categoryDetails: "api/magazine/v1/category_articles_list/",
  activePlans: "api/magazine/v1/active_plans",
  notifications: "api/magazine/v1/notification_list?page=1&limit=10",
  editProfile: "api/auth/v1/profile_edit",
  addClaps: "api/magazine/v1/add_article_claps/",
  removeClaps: "api/magazine/v1/remove_article_claps/",
  searchArticle: "api/magazine/v1/search_articles_list",
  guestDashboard: "api/magazine/v1/dashboard",

  // pravasi vayana apis
  getQuiz: "api/magazine/v1/user_view_quiz/",
  answerQuiz: "api/magazine/v1/user_answer_quiz/",
  getQuizStatus: "api/magazine/v1/quiz_status/",
  getQuizWinners: "api/magazine/v1/quiz_winners_list/",
};

export default urls;
