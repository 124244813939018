import * as React from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  Avatar,
  Box,
} from "@mui/material";

import TurnedInIcon from "@mui/icons-material/TurnedIn";

// style constant
const useStyles = makeStyles((theme) => ({
  sideIconCard: {
    "&.MuiCard-root": {
      position: "relative",
      borderRadius: "6px",
      boxShadow: "none !important",
    },
  },
  sideCardContent: {
    "&.MuiCardContent-root": {
      "&:last-child": {
        padding: "10px !important",
      },
    },
  },
  sideIcon: {
    "& > svg": {
      width: "19px",
      height: "19px",
    },
  },
  premium: {
    "&.MuiTypography-root": {
      marginLeft: "12px",
      fontSize: "19px",
      fontWeight: 600,
      color: "#303030",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
  },
  primaryTextBlock: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      color: "#868686",
      fontWeight: 500,
      marginLeft: "12px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },
  primaryText: {
    "&.MuiTypography-root": {
      marginLeft: "12px",
      fontSize: "12px",
      fontWeight: 600,
      marginTop: "2px",
      color: theme.palette.common.primary,
    },
  },
  cardImage: {
    width: "-webkit-fill-available",
    padding: " 0px",
    borderRadius: "5px;",
    objectFit: "cover",
    aspectRatio: "3/4",
  },
  headerAvatar: {
    "&.MuiAvatar-root": {
      width: "20px",
      height: "20px",
      marginLeft: "15px",
    },
  },
  profileChip: {
    height: "48px",
    alignItems: "center",
    borderRadius: "27px",
    transition: "all .2s ease-in-out",
  },
  bookMarkIcon: {
    "&.MuiSvgIcon-root": {
      width: "18px !important",
      color: theme.palette.common.primary,
    },
  },
}));

// =============================|| SIDE ICON CARD ||============================= //

export const Notification = ({
  iconPrimary,
  primary,
  secondary,
  secondarySub,
}) => {
  const classes = useStyles();

  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("xs"));

  const IconPrimary = iconPrimary;
  const primaryIcon = iconPrimary ? <IconPrimary /> : null;

  return (
    <Card className={classes.sideIconCard}>
      <CardContent className={classes.sideCardContent}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={2} className={classes.sideIcon}>
            <img className={classes.cardImage} alt="image" src={iconPrimary} />
          </Grid>
          <Grid item xs={10}>
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              spacing={1}
              alignItems={matchDownXs ? "center" : "flex-start"}
            >
              <Grid item sm={12}>
                <Typography variant="h6" className={classes.premium}>
                  {primary}
                </Typography>
                <Typography variant="h6" className={classes.primaryTextBlock}>
                  <span>{secondarySub}</span>
                </Typography>
                <Typography variant="h6" className={classes.primaryText}>
                  {secondary}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

Notification.propTypes = {
  iconPrimary: PropTypes.object,
  primary: PropTypes.string,
  secondary: PropTypes.string,
  secondarySub: PropTypes.string,
};
