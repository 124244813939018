import React, { useState, useEffect, Fragment, useRef } from "react";
import { Box, Container, Grid, Link, Skeleton, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  ArticleCard,
  FeaturedArticleCard,
  TopArticleCard,
  LatestMagazineCard,
  TitleBar,
  CategoryCard,
  BottomBanner,
} from "../../components";
import QuoteBanner from "../../components/cards/QuoteBanner";
import { useNavigate } from "react-router-dom";
import { api, urls } from "../../services";
import { useToast, useAuth } from "../../contexts";

//Icons
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosNewOutlinedIcon from "@mui/icons-material/ArrowBackIosNewOutlined";

const useStyles = makeStyles((theme) => ({
  cardSpace: {
    "&.MuiGrid-root": {
      padding: "20px 15px 0px 0px",
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2.5),
    },
  },

  containerSecond: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: "#F6F6F6",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

export function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const classes = useStyles();
  const { logout, isLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  const [scrollLeftOne, setScrollLeftOne] = useState(0);
  const [scrollLeftTwo, setScrollLeftTwo] = useState(0);
  const [scrollLeftThree, setScrollLeftThree] = useState(0);
  const [scrollLeftFour, setScrollLeftFour] = useState(0);
  const [reachedLeftOne, setReachedLeftOne] = useState(true);
  const [reachedLeftTwo, setReachedLeftTwo] = useState(true);
  const [reachedLeftThree, setReachedLeftThree] = useState(true);
  const [reachedLeftFour, setReachedLeftFour] = useState(true);
  const [reachedRightOne, setReachedRightOne] = useState(true);
  const [reachedRightTwo, setReachedRightTwo] = useState(true);
  const [reachedRightThree, setReachedRightThree] = useState(true);
  const [reachedRightFour, setReachedRightFour] = useState(true);

  const gridItemRef1 = useRef(null);
  const gridItemRef2 = useRef(null);
  const gridItemRef3 = useRef(null);
  const gridItemRef4 = useRef(null);

  const getDashboard = () => {
    setIsLoading(true);
    api
      .get(isLoggedIn ? urls.dashboard : urls.guestDashboard)
      .then((res) => {
        setDashboardData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401) {
          logout();
        } else {
          showToast(err.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    //check for scroll
    const container1 = document.getElementById(`grid-container-1`);
    const hasVerticalScroll1 =
      container1?.scrollWidth > container1?.clientWidth;
    setReachedRightOne(!hasVerticalScroll1);

    //check for scroll
    const container2 = document.getElementById(`grid-container-2`);
    const hasVerticalScroll2 =
      container2?.scrollWidth > container2?.clientWidth;
    setReachedRightTwo(!hasVerticalScroll2);

    //check for scroll
    const container3 = document.getElementById(`grid-container-3`);
    const hasVerticalScroll3 =
      container3?.scrollWidth > container3?.clientWidth;
    setReachedRightThree(!hasVerticalScroll3);

    //check for scroll
    const container4 = document.getElementById(`grid-container-4`);
    const hasVerticalScroll4 =
      container4?.scrollWidth > container4?.clientWidth;
    setReachedRightFour(!hasVerticalScroll4);
  }, [dashboardData]);

  const showAuthorDetails = (uid) => {
    navigate(`/author-detail/${uid}`);
  };

  const handleNext1 = () => {
    const container = document.getElementById(`grid-container-1`);
    if (container) {
      const itemWidth = gridItemRef1.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);

      const nextScrollLeft = scrollLeftOne + itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftOne(nextScrollLeft);
    }
  };

  const handlePrev1 = () => {
    const container = document.getElementById(`grid-container-1`);
    if (container) {
      const itemWidth = gridItemRef1.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);
      const nextScrollLeft = scrollLeftOne - itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftOne(nextScrollLeft);
    }
  };

  const handleNext2 = () => {
    const container = document.getElementById(`grid-container-2`);
    if (container) {
      const itemWidth = gridItemRef2.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);

      const nextScrollLeft = scrollLeftTwo + itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftTwo(nextScrollLeft);
    }
  };

  const handlePrev2 = () => {
    const container = document.getElementById(`grid-container-2`);
    if (container) {
      const itemWidth = gridItemRef2.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);
      const nextScrollLeft = scrollLeftTwo - itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftTwo(nextScrollLeft);
    }
  };

  const handleNext3 = () => {
    const container = document.getElementById(`grid-container-3`);
    if (container) {
      const itemWidth = gridItemRef3.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);
      const nextScrollLeft = scrollLeftThree + itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftThree(nextScrollLeft);
    }
  };

  const handlePrev3 = () => {
    const container = document.getElementById(`grid-container-3`);
    if (container) {
      const itemWidth = gridItemRef3.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);
      const nextScrollLeft = scrollLeftThree - itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftThree(nextScrollLeft);
    }
  };

  const handleNext4 = () => {
    const container = document.getElementById(`grid-container-4`);
    if (container) {
      const itemWidth = gridItemRef4.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);
      const nextScrollLeft = scrollLeftFour + itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftFour(nextScrollLeft);
    }
  };

  const handlePrev4 = () => {
    const container = document.getElementById(`grid-container-4`);
    if (container) {
      const itemWidth = gridItemRef4.current.offsetWidth;
      const itemsPerPage = Math.floor(container.offsetWidth / itemWidth);
      const nextScrollLeft = scrollLeftFour - itemsPerPage * itemWidth;

      container.scrollTo({
        left: nextScrollLeft,
        behavior: "smooth",
      });
      setScrollLeftFour(nextScrollLeft);
    }
  };

  // Custom arrows components
  const SliderNextArrow1 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          right: "0",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handleNext1}
      >
        <ArrowForwardIosOutlinedIcon />
      </Box>
    );
  };

  const SliderPrevArrow1 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          left: "0",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handlePrev1}
      >
        <ArrowBackIosNewOutlinedIcon />
      </Box>
    );
  };

  const SliderNextArrow2 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          right: "0",
          top: "35%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handleNext2}
      >
        <ArrowForwardIosOutlinedIcon />
      </Box>
    );
  };

  const SliderPrevArrow2 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          left: "0",
          top: "35%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handlePrev2}
      >
        <ArrowBackIosNewOutlinedIcon />
      </Box>
    );
  };

  const SliderNextArrow3 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          right: "0",
          top: "35%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handleNext3}
      >
        <ArrowForwardIosOutlinedIcon />
      </Box>
    );
  };

  const SliderPrevArrow3 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          left: "0",
          top: "35%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handlePrev3}
      >
        <ArrowBackIosNewOutlinedIcon />
      </Box>
    );
  };

  const SliderNextArrow4 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          right: "0",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handleNext4}
      >
        <ArrowForwardIosOutlinedIcon />
      </Box>
    );
  };

  const SliderPrevArrow4 = () => {
    return (
      <Box
        sx={{
          backgroundColor: "#00000095",
          height: "70px",
          width: "40px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "30px",
          "&:hover": {
            backgroundColor: "#000000",
            color: "#fff",
            cursor: "pointer",
          },
          position: "absolute",
          left: "0",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 5,
        }}
        onClick={handlePrev4}
      >
        <ArrowBackIosNewOutlinedIcon />
      </Box>
    );
  };

  return (
    <Fragment>
      <Container maxWidth="xl">
        {!isLoading && dashboardData?.daily_quote && (
          <QuoteBanner quoteData={dashboardData?.daily_quote} />
        )}
        {isLoading && (
          <Grid
            container
            className={classes.container}
            spacing={1}
            direction="row"
          >
            <Grid item xs={12}>
              <TitleBar
                Title="Latest Magazine"
                isViewMore={true}
                link="/magazines"
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                sx={{ flexWrap: "nowrap", overflowX: "auto" }}
                className="categories-slider"
              >
                {isLoading &&
                  [1, 2, 3, 4, 5].map((index) => (
                    <Grid
                      item
                      key={index}
                      sx={{
                        mr: { xs: 1.5, md: 2.5, lg: 3.5 },
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{
                          width: { xs: 200, md: 290, lg: 350 },
                          height: { xs: 310, md: 450, lg: 550 },
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>
        )}

        {!isLoading && (
          <Grid
            container
            className={classes.container}
            spacing={1}
            direction="row"
          >
            <Grid item xs={12}>
              <TitleBar
                Title="Latest Magazine"
                isViewMore={true}
                link="/magazines"
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                }}
              >
                {!reachedLeftOne && <SliderPrevArrow1 />}
                <div style={{ overflowX: "auto", flexGrow: 1 }}>
                  <Grid
                    container
                    sx={{ flexWrap: "nowrap", overflowX: "auto" }}
                    id={`grid-container-1`}
                    className="categories-slider"
                    onScroll={(e) => {
                      const container = e.target;
                      const singleItemWidth = gridItemRef1.current.offsetWidth;
                      setReachedLeftOne(
                        container.scrollLeft <= singleItemWidth / 20
                      );
                      setReachedRightOne(
                        container.scrollLeft >=
                          container.scrollWidth -
                            container.clientWidth -
                            singleItemWidth / 10
                      );
                    }}
                  >
                    {dashboardData?.latest_magazines?.map((post, i) => (
                      <Grid
                        item
                        key={i}
                        sx={{
                          mr: { xs: 1.5, md: 2.5, lg: 3.5 },
                        }}
                        ref={gridItemRef1}
                      >
                        <Link
                          href={`magazine-detail/${post.id}`}
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <LatestMagazineCard
                            image={post.cover_image}
                            date={post.issue_name}
                            bookNumber={post.book_number}
                            cardColor={post.colour}
                            year={post.year_number}
                            isOpened={post.opened}
                          />
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                {!reachedRightOne && <SliderNextArrow1 />}
              </div>
            </Grid>
          </Grid>
        )}

        {/* Free Articles */}
        {!isLoading && dashboardData?.free_articles?.length > 0 && (
          <Grid
            container
            className={classes.container}
            spacing={2}
            direction="row"
          >
            <Grid item md={12} xs={12} sm={12}>
              <TitleBar
                Title="Free Articles"
                isViewMore={true}
                link="/free-articles"
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                }}
              >
                {!reachedLeftTwo && <SliderPrevArrow2 />}
                <div style={{ overflowX: "auto", flexGrow: 1 }}>
                  <Grid
                    container
                    sx={{ flexWrap: "nowrap", overflowX: "auto" }}
                    id={`grid-container-2`}
                    className="categories-slider"
                    onScroll={(e) => {
                      const container = e.target;
                      const singleItemWidth = gridItemRef2.current.offsetWidth;
                      setReachedLeftTwo(
                        container.scrollLeft <= singleItemWidth / 20
                      );
                      setReachedRightTwo(
                        container.scrollLeft >=
                          container.scrollWidth -
                            container.clientWidth -
                            singleItemWidth / 10
                      );
                    }}
                  >
                    {dashboardData?.free_articles?.map((post, i) => (
                      <Grid
                        item
                        // md={3}
                        sx={{ mr: { xs: 1.5, md: 3.8 } }}
                        key={i}
                        ref={gridItemRef2}
                      >
                        <Link
                          href={`article-details/${post.id}`}
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <TopArticleCard
                            iconPrimary={post.feature_image}
                            primary={post.title}
                            secondary={post.author.name}
                            authorImage={post.author.image}
                            secondarySub={
                              post.article_categories[0].descriptions[0]
                                .category_name
                            }
                            color={post.article_categories[0].category_colour}
                            backgroundColor={alpha(
                              post.article_categories[0].category_colour,
                              0.1
                            )}
                            onClickAuthor={() =>
                              showAuthorDetails(post.author.id)
                            }
                          />
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                {!reachedRightTwo && <SliderNextArrow2 />}
              </div>
            </Grid>
          </Grid>
        )}
      </Container>

      {/* Trending Articles */}
      <Grid container className={classes.containerSecond} direction="row">
        <Container maxWidth="xl" className={classes.root} spacing={2}>
          <Grid
            container
            className={classes.containerSecond}
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
          >
            <Grid item xs={12}>
              <TitleBar
                Title="Trending Articles"
                isViewMore={true}
                link="/articles"
              />
            </Grid>
            {isLoading &&
              [1, 2, 3, 4, 5, 6].map((index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <Skeleton
                    variant="rounded"
                    sx={{
                      height: { xs: 120, md: 150, lg: 180 },
                    }}
                  />
                </Grid>
              ))}
            {!isLoading &&
              dashboardData?.trending_articles?.map((post, i) => (
                <Grid item xs={12} sm={6} lg={4} key={i}>
                  <Link
                    href={`article-details/${post.id}`}
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ArticleCard
                      iconPrimary={post.feature_image}
                      primary={post.title}
                      secondary={post.author.name}
                      authorImage={post.author.image}
                      secondarySub={
                        post.article_categories[0].descriptions[0].category_name
                      }
                      color={post.article_categories[0].category_colour}
                      backgroundColor={alpha(
                        post.article_categories[0].category_colour,
                        0.1
                      )}
                      onClickAuthor={() => showAuthorDetails(post.author.id)}
                    />
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Container>
      </Grid>

      {/* Categories */}
      <Container maxWidth="xl" className={classes.root}>
        {!isLoading && dashboardData?.article_categories?.length > 0 && (
          <Grid container className={classes.container} direction="row">
            <Grid item md={12} xs={12} sm={12}>
              <TitleBar Title="Categories" />
            </Grid>
            <Box
              className="categories-slider"
              sx={{ display: "flex", flexWrap: "no-wrap", overflowX: "auto" }}
            >
              {dashboardData?.article_categories?.map((post, i) => (
                <Grid key={i} className={classes.cardSpace}>
                  <CategoryCard
                    primaryText={post.descriptions[0].category_name}
                    color={post.category_colour}
                    onItemClick={() => {
                      navigate(`/category-details/${post.id}`);
                    }}
                  />
                </Grid>
              ))}
            </Box>
          </Grid>
        )}

        {/* Top Articles */}
        {!isLoading && dashboardData?.top_articles?.length > 0 && (
          <Grid
            container
            className={classes.container}
            spacing={2}
            direction="row"
          >
            <Grid item md={12} xs={12} sm={12}>
              <TitleBar
                Title="Top Articles"
                isViewMore={true}
                link="/top-articles"
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                }}
              >
                {!reachedLeftThree && <SliderPrevArrow3 />}
                <div style={{ overflowX: "auto", flexGrow: 1 }}>
                  <Grid
                    container
                    sx={{ flexWrap: "nowrap", overflowX: "auto" }}
                    id={`grid-container-3`}
                    className="categories-slider"
                    onScroll={(e) => {
                      const container = e.target;
                      const singleItemWidth = gridItemRef3.current.offsetWidth;
                      setReachedLeftThree(
                        container.scrollLeft <= singleItemWidth / 20
                      );
                      setReachedRightThree(
                        container.scrollLeft >=
                          container.scrollWidth -
                            container.clientWidth -
                            singleItemWidth / 10
                      );
                    }}
                  >
                    {dashboardData?.top_articles?.map((post, i) => (
                      <Grid
                        item
                        // md={3}
                        sx={{ mr: { xs: 1.5, md: 3.8 } }}
                        key={i}
                        ref={gridItemRef3}
                      >
                        <Link
                          href={`article-details/${post.id}`}
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <TopArticleCard
                            iconPrimary={post.feature_image}
                            primary={post.title}
                            secondary={post.author.name}
                            authorImage={post.author.image}
                            secondarySub={
                              post.article_categories[0].descriptions[0]
                                .category_name
                            }
                            color={post.article_categories[0].category_colour}
                            backgroundColor={alpha(
                              post.article_categories[0].category_colour,
                              0.1
                            )}
                            onClickAuthor={() =>
                              showAuthorDetails(post.author.id)
                            }
                          />
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                {!reachedRightThree && <SliderNextArrow3 />}
              </div>
            </Grid>
          </Grid>
        )}

        {/* Featured Articles */}
        {!isLoading && dashboardData?.featured_articles?.length > 0 && (
          <Grid
            container
            className={classes.container}
            spacing={2}
            direction="row"
          >
            <Grid item md={12} xs={12} sm={12}>
              <TitleBar
                Title="Featured Articles"
                isViewMore={true}
                link="/featured-articles"
              />
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  position: "relative",
                }}
              >
                {!reachedLeftFour && <SliderPrevArrow4 />}
                <div style={{ overflowX: "auto", flexGrow: 1 }}>
                  <Grid
                    container
                    sx={{ flexWrap: "nowrap", overflowX: "auto" }}
                    id={`grid-container-4`}
                    className="categories-slider"
                    onScroll={(e) => {
                      const container = e.target;
                      const singleItemWidth = gridItemRef4.current.offsetWidth;
                      setReachedLeftFour(
                        container.scrollLeft <= singleItemWidth / 20
                      );
                      setReachedRightFour(
                        container.scrollLeft >=
                          container.scrollWidth -
                            container.clientWidth -
                            singleItemWidth / 10
                      );
                    }}
                  >
                    {dashboardData?.featured_articles?.map((post, i) => (
                      <Grid
                        item
                        // md={4}
                        sx={{ mr: { xs: 1.5, md: 2.5 } }}
                        key={i}
                        ref={gridItemRef4}
                      >
                        <Link
                          href={`article-details/${post.id}`}
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none" }}
                        >
                          <FeaturedArticleCard
                            iconPrimary={post.feature_image}
                            primary={post.title}
                            secondary={post.author.name}
                            authorImage={post.author.image}
                            secondarySub={
                              post.article_categories[0].descriptions[0]
                                .category_name
                            }
                            color={post.article_categories[0].category_colour}
                            backgroundColor={alpha(
                              post.article_categories[0].category_colour,
                              0.1
                            )}
                            onClickAuthor={() =>
                              showAuthorDetails(post.author.id)
                            }
                          />
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                </div>
                {!reachedRightFour && <SliderNextArrow4 />}
              </div>
            </Grid>
          </Grid>
        )}

        <Grid container className={classes.container} direction="row">
          <Grid item md={12} sx={{ width: "100%" }}>
            <BottomBanner />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
}
